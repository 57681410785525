var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-text-field',{staticClass:"ml-auto mt-4 break-search bs-atualizacao-bdgd",staticStyle:{"max-width":"250px"},attrs:{"id":"atualizacao-bdgd-historico-search","append-icon":"mdi-magnify","label":"Procurar","hide-details":"","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('general-progress-bars',{staticClass:"mt-11",attrs:{"items":_vm.downloadProgressData,"prefixText":"Baixando logs ..."}}),_c('v-divider',{staticClass:"mt-10"}),_c('v-data-table',{staticClass:"atualizacoes-bdgd-table",attrs:{"headers":[
      {
        text: '#',
        value: 'id'
      },
      {
        text: 'Empresa',
        value: 'empresa'
      },
      {
        text: 'Título',
        value: 'titulo'
      },
      {
        text: 'Script Modelo',
        value: 'script_modelo'
      },
      {
        text: 'Mês dos dados',
        value: 'data_registro'
      },
      {
        text: 'Status',
        value: 'status'
      },
      {
        sortable: false,
        text: 'Ações',
        value: 'actions',
        class: 'pl-4'
      }
    ],"items":_vm.items,"search":_vm.search,"headerProps":{
      sortByText: 'Ordenar Por'
    },"footer-props":{
      itemsPerPageText: 'Itens por Página'
    },"loading":_vm.loading,"hide-default-header":_vm.loading,"hide-default-footer":_vm.loading,"mobile-breakpoint":800,"no-data-text":"Nenhum ajuste de BDGD encontrado","no-results-text":"Nenhum registro correspondente encontrado"},on:{"update:search":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("addLeadingZero")(item.id))+" ")]}},{key:"item.titulo",fn:function(ref){
    var item = ref.item;
return [_c('span',{attrs:{"title":item.descricao}},[_vm._v(" "+_vm._s(item.titulo)+" ")])]}},{key:"item.script_modelo",fn:function(ref){
    var item = ref.item;
return [_c('span',{attrs:{"title":item.script_modelo_descricao}},[_vm._v(" "+_vm._s(item.script_modelo_nome)+" ")])]}},{key:"item.data_registro",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatToMonth")(item.data_registro))+" v"+_vm._s(item.versao)+" ")]}},{key:"item.status",fn:function(ref){
    var item = ref.item;
return [_c('general-status',{attrs:{"status":item.status}})]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{staticClass:"px-1",class:!_vm.podeBaixarZip(item.status) ? 'cursor-block' : '',attrs:{"id":"action-download-bdgd","min-width":"0","fab":"","icon":"","x-small":"","loading":_vm.downloadProgressData[item.id] !== undefined ? true : false,"disabled":!_vm.podeBaixarZip(item.status)},on:{"click":function($event){return _vm.baixarZip(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-download ")])],1),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"id":"action-more-rel","color":"white","height":"22px","width":"22px","fab":"","x-small":"","elevation":"1"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi mdi-dots-vertical ")])],1)]}}],null,true)},[_c('v-list',[(_vm.podeExecutarItem(item.status))?_c('v-list-item',{on:{"click":function($event){return _vm.executarItem(item.id)}}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-play ")]),_vm._v(" Executar ")],1)],1):_vm._e(),(_vm.podeDeletarItem(item.status))?_c('v-list-item',{on:{"click":function($event){return _vm.deletarItem(item.id)}}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-trash-can-outline ")]),_vm._v(" Deletar ")],1)],1):_vm._e(),_c('v-list-item',{on:{"click":function($event){(_vm.dialogCreationLog = true),
                (_vm.dialogCreationLogData = {
                  username: item.usuario,
                  created: item.created
                })}}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-table-search ")]),_vm._v(" Log ")],1)],1)],1)],1)]}},{key:"footer.page-text",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"},on:{"click":function($event){return _vm.$emit('rechargeTable')}}},'v-icon',attrs,false),on),[_vm._v("mdi-refresh")])]}}])},[_c('span',[_vm._v("Clique aqui para recarregar os scripts de atualização de BDGD")])])]},proxy:true}],null,true)}),_c('dialog-creation-log',{attrs:{"dialog":_vm.dialogCreationLog,"item":_vm.dialogCreationLogData},on:{"update:dialog":function($event){_vm.dialogCreationLog=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }