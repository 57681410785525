import APIService from './APIService';

export default {
  getAtualizacoes() {
    return new Promise((resolve, reject) => {
      APIService.apiCall()
        .get('/atualizacoes-bdgd/get-atualizacoes')
        .then((response) => resolve(response.data.data))
        .catch((error) => reject(error));
    });
  },
  getAllowedFiles() {
    return APIService.apiCall().get(
      '/atualizacoes-bdgd/get-arquivos-permitidos'
    );
  },
  save(data) {
    return new Promise((resolve, reject) => {
      APIService.apiCall()
        .post('/atualizacoes-bdgd/salvar', JSON.stringify(data))
        .then(() => resolve(true))
        .catch(() => reject(false));
    });
  },
  deletar(id) {
    return APIService.apiCall().get(`/atualizacoes-bdgd/excluir/${id}`);
  },
  executar(id) {
    return APIService.apiCall().get(`/atualizacoes-bdgd/executar/${id}`);
  },
  baixarZip(id, config = {}) {
    return APIService.apiCall().get(
      `/atualizacoes-bdgd/download-arquivo-zip-logs/${id}`,
      { responseType: 'blob', ...config }
    );
  },
  baixarArquivoModeloAtualizacaoEntidadeTipoPoligono() {
    return APIService.apiCall().get(
      '/atualizacoes-bdgd/entidade-tipo-poligono/download-arquivo-modelo-atualizacao',
      {
        responseType: 'blob',
        ...{
          timeout: 60 * 60 * 1000
        }
      }
    );
  }
};
