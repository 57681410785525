<template>
  <v-container fluid>
    <v-text-field
      id="atualizacao-bdgd-historico-search"
      v-model="search"
      append-icon="mdi-magnify"
      class="ml-auto mt-4 break-search bs-atualizacao-bdgd"
      label="Procurar"
      hide-details
      single-line
      style="max-width: 250px"
    />
    <general-progress-bars
      class="mt-11"
      :items="downloadProgressData"
      prefixText="Baixando logs ..."
    />
    <v-divider class="mt-10" />
    <v-data-table
      class="atualizacoes-bdgd-table"
      :headers="[
        {
          text: '#',
          value: 'id'
        },
        {
          text: 'Empresa',
          value: 'empresa'
        },
        {
          text: 'Arquivo',
          value: 'arquivo'
        },
        {
          text: 'Mês dos dados',
          value: 'data_registro'
        },
        {
          text: 'Nº de linhas',
          value: 'numero_linhas'
        },
        {
          text: 'Status',
          value: 'status'
        },
        {
          sortable: false,
          text: 'Ações',
          value: 'actions',
          class: 'pl-4'
        }
      ]"
      :items="items"
      :search.sync="search"
      :headerProps="{
        sortByText: 'Ordenar Por'
      }"
      :footer-props="{
        itemsPerPageText: 'Itens por Página'
      }"
      :loading="loading"
      :hide-default-header="loading"
      :hide-default-footer="loading"
      :mobile-breakpoint="800"
      no-data-text="Nenhum ajuste de BDGD encontrado"
      no-results-text="Nenhum registro correspondente encontrado"
    >
      <template v-slot:[`item.id`]="{ item }">
        {{ item.id | addLeadingZero }}
      </template>
      <template v-slot:[`item.arquivo`]="{ item }">
        <span :title="item.descricao">
          {{ item.arquivo }} ({{ item.entidade }})
        </span>
      </template>
      <template v-slot:[`item.data_registro`]="{ item }">
        {{ item.data_registro | formatToMonth }} v{{ item.versao }}
      </template>
      <template v-slot:[`item.numero_linhas`]="{ item }">
        <span
          title="Número aproximado de linhas afetadas pela atualização (pode não ser exato)"
        >
          {{ item.numero_linhas | parseNumberToIntegerBR }}
        </span>
        <span
          v-if="item.numero_inserts_gerados"
          title="Número de linhas inseridas pela atualização"
        >
          ({{ item.numero_inserts_gerados | parseNumberToIntegerBR }} inseridas)
        </span>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <general-status :status="item.status" />
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          :class="!podeBaixarZip(item.status) ? 'cursor-block' : ''"
          id="action-download-bdgd"
          class="px-1"
          min-width="0"
          fab
          icon
          x-small
          @click="baixarZip(item)"
          :loading="downloadProgressData[item.id] !== undefined ? true : false"
          :disabled="!podeBaixarZip(item.status)"
        >
          <v-icon small> mdi-download </v-icon>
        </v-btn>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              id="action-more-rel"
              color="white"
              class="ml-1"
              height="22px"
              width="22px"
              fab
              x-small
              elevation="1"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon small> mdi mdi-dots-vertical </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-if="podeExecutarItem(item.status)"
              @click="executarItem(item.id)"
            >
              <v-list-item-title>
                <v-icon small> mdi-play </v-icon>
                Executar
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="podeDeletarItem(item.status)"
              @click="deletarItem(item.id)"
            >
              <v-list-item-title>
                <v-icon small> mdi-trash-can-outline </v-icon>
                Deletar
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="
                (dialogCreationLog = true),
                  (dialogCreationLogData = {
                    username: item.usuario,
                    created: item.created
                  })
              "
            >
              <v-list-item-title>
                <v-icon small> mdi-table-search </v-icon>
                Log
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:[`footer.page-text`]>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="primary" v-bind="attrs" v-on="on" @click="$emit('rechargeTable')">mdi-refresh</v-icon>
          </template>
          <span>Clique aqui para recarregar as atualizações de BDGD</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <dialog-creation-log
      :dialog.sync="dialogCreationLog"
      :item="dialogCreationLogData"
    />
  </v-container>
</template>

<script>
import dayjs from 'dayjs';
import AtualizacoesBdgdService from '@/services/AtualizacoesBdgdService';
import powerupsActionsMixins from '@/mixins/powerupsActionsMixins.js';

export default {
  name: 'AtualizacoesBdgdDataTable',
  mixins: [powerupsActionsMixins],
  components: {
    DialogCreationLog: () => import('@/components/general/DialogCreationLog'),
    GeneralStatus: () => import('@/components/general/GeneralStatus.vue'),
    GeneralProgressBars: () =>
      import('@/components/general/GeneralProgressBars.vue')
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    powerupService: AtualizacoesBdgdService,
    powerupEntityName: 'Atualizações da BDGD',
    search: ''
  }),
  methods: {
    podeExecutarItem(status) {
      return status === 'SUCESSO' || status === 'FALHA';
    },
    executarItem(id) {
      if (
        !confirm(`Tem certeza que deseja executar novamente o ajuste #${id}?`)
      ) {
        return;
      }
      AtualizacoesBdgdService.executar(id)
        .then(() => {
          this.$toast.info(
            'O ajuste de BDGD ocorrerá em instantes. Por favor, aguarde.',
            '',
            { position: 'topRight', timeout: 2000 }
          );
          this.$emit('rechargeTable');
        })
        .catch(() => {
          this.$toast.error('Erro ao executar o ajuste de BDGD.', '', {
            position: 'topRight'
          });
        });
    },
    podeDeletarItem(status) {
      return status === 'AGUARDANDO';
    },
    deletarItem(id) {
      if (!confirm(`Tem certeza que deseja remover o ajuste #${id}?`)) return;
      AtualizacoesBdgdService.deletar(id)
        .then(() => {
          this.$toast.info('Ajuste de BDGD removido com sucesso.', '', {
            position: 'topRight',
            timeout: 2000
          });
          this.$emit('rechargeTable');
        })
        .catch(() => {
          this.$toast.error('Erro ao remover o ajuste de BDGD.', '', {
            position: 'topRight'
          });
        });
    },
    podeBaixarZip(status) {
      return status === 'SUCESSO' || status === 'FALHA';
    },
    baixarZip(atualizacao) {
      const { id, entidade, data_registro: dataRegistro, versao } = atualizacao;
      const timestamp = dayjs(new Date()).format('YYYYMMDDHHmm');
      const nomeArquivo = `atualizacao-${entidade.toLowerCase()}-mes-${dataRegistro}-versao-${versao}-${timestamp}.zip`;

      this.$set(this.downloadProgressData, id, { id });
      this.$toast.info('Preparando seu arquivo para download.', '', {
        position: 'topRight',
        timeout: 2000
      });

      AtualizacoesBdgdService.baixarZip(id, {
        timeout: 60 * 60 * 1000,
        onDownloadProgress: (progressEvent) => {
          let progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          let isComputable = progressEvent.lengthComputable;
          this.$set(this.downloadProgressData, id, {
            progress,
            isComputable,
            id
          });
        }
      })
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', nomeArquivo);
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          this.$toast.error(
            'Erro ao baixar o arquivo zip com os logs da atualização da BDGD.',
            '',
            { position: 'topRight' }
          );
          console.error('Erro: ', error);
        })
        .finally(() => {
          this.$delete(this.downloadProgressData, id);
        });
    }
  }
};
</script>
