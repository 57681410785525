<template>
  <v-container
    fluid
    class="pt-0"
    v-if="items.length"
  >
    <v-text-field
      v-model="search"
      append-icon="mdi-magnify"
      class="mb-5"
      label="Procurar no histórico de ajustes"
      hide-details
      single-line
    />
    <v-data-table
      :headers="headers"
      :items="items"
      :search.sync="search"
      :mobile-breakpoint="800"
      :loading="loading"
      :footer-props="{
        itemsPerPageText: 'Linhas'
      }"
      :options="{
        itemsPerPage
      }"
      no-data-text="Nenhum log encontrado"
    >
      <template v-slot:[`item.entidade`]="{ item }">
        <span
          :class="{
            alerta: item.acao === 'UPDATE',
            perigo: item.acao === 'DELETE'
          }"
        >
          {{ item.entidade | toUpperCase }} ({{ item.acao | toUpperCase }})
        </span>
      </template>
      <template v-slot:[`item.dado_antes_ajuste`]="{ item }">
        <span v-if="item.acao === 'DELETE'">
          <v-btn
            color="gray"
            min-width="0"
            class="px-1"
            fab
            icon
            x-small
            @click="openDialogViewDeletedRow(item)"
          >
            <v-icon small> mdi-eye-outline </v-icon>
          </v-btn>
        </span>
        <span v-else>
          {{ item.dado_antes_ajuste | treatDinamic(item.tipo_campo) }}
        </span>
      </template>
      <template v-slot:[`item.dado_depois_ajuste`]="{ item }">
        {{ item.dado_depois_ajuste | treatDinamic(item.tipo_campo) }}
      </template>
      <template v-slot:[`item.data_ajuste`]="{ item }">
        {{ item.data_ajuste | parseToDateTimeBR }}
      </template>
    </v-data-table>
    <dialog-detalhes
      :title="`${dialogDeletedRowEntity} :: linha #${dialogDeletedRowData.gid} removida`"
      :dialog.sync="dialogDeletedRow"
    >
      <template slot="body">
        <v-simple-table
          class="ml-3"
          height="400px"
          fixed-header
          dense
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Campo</th>
                <th class="text-left">Valor</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in deletedRow"
                :key="item.campo + item.gid"
              >
                <td>{{ item.campo }}</td>
                <td>{{ item.valor }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </template>
    </dialog-detalhes>
  </v-container>
</template>

<script>
import EntidadesBdgdService from '@/services/EntidadesBdgdService';
import camposFiltrosMixins from '@/mixins/camposFiltrosMixins';

export default {
  name: 'LogsAjustesManuaisEntidadesBdgdDataTable',
  components: {
    DialogDetalhes: () => import('@/components/general/DialogDetalhes')
  },
  mixins: [camposFiltrosMixins],
  props: {
    bdgdVersao: {
      type: Object,
      required: true,
      default: () => {}
    },
    itemsPerPage: {
      type: Number,
      default: 10
    },
    limit: {
      type: Number,
      default: 1000
    }
  },
  data: () => ({
    search: '',
    items: [],
    loading: false,
    headers: [
      {
        text: '#',
        value: 'gid'
      },
      {
        text: 'COD_ID',
        value: 'cod_id'
      },
      {
        text: 'Entidade',
        value: 'entidade'
      },
      {
        text: 'Campo',
        value: 'campo'
      },
      {
        text: 'Antes',
        value: 'dado_antes_ajuste'
      },
      {
        text: 'Depois',
        value: 'dado_depois_ajuste'
      },
      {
        text: 'Ajustado por',
        value: 'nome_usuario'
      },
      {
        text: 'Ajustado em',
        value: 'data_ajuste'
      }
    ],
    dialogDeletedRow: false,
    dialogDeletedRowEntity: null,
    dialogDeletedRowData: {}
  }),
  created() {
    this.getLogsAjustes();
  },
  methods: {
    getLogsAjustes() {
      if (!(this.bdgdVersao && this.bdgdVersao.id)) return;
      this.loading = true;
      EntidadesBdgdService.getLogsAjustesPorVersaoBdgd(
        this.bdgdVersao.id,
        this.limit
      )
        .then((response) => (this.items = response.data))
        .catch(() =>
          this.$toast.error(
            'Erro ao recuperar logs de ajustes efetuados nas entidades da BDGD na versão selecionada.',
            '',
            { position: 'topRight' }
          )
        )
        .finally(() => (this.loading = false));
    },
    openDialogViewDeletedRow({ entidade, dado_antes_ajuste }) {
      this.dialogDeletedRowEntity = entidade.toUpperCase();
      this.dialogDeletedRowData = JSON.parse(dado_antes_ajuste);
      this.dialogDeletedRow = true;
    }
  },
  computed: {
    deletedRow() {
      let deletedRow = [];
      const ignorarCampos = this.getIgnorarCamposFiltros();
      Object.entries(this.dialogDeletedRowData).forEach((item) => {
        const [campo, valor] = item;
        if (ignorarCampos.includes(campo)) return;
        deletedRow.push({
          campo,
          valor
        });
      });
      return deletedRow;
    }
  },
  watch: {
    bdgdVersao() {
      this.getLogsAjustes();
    }
  }
};
</script>

<style scoped>
.alerta {
  color: #ffa726;
  font-weight: 400;
}

.perigo {
  color: #e53935;
  font-weight: 400;
}
</style>
