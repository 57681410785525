<template>
  <v-container
    id="atualizacoes-bdgd-historico"
    fluid
    tag="section"
  >
    <v-row>
      <v-col cols="12">
        <base-material-card
          color="primary"
          icon="mdi-database-edit"
          inline
          class="px-5 py-4 ml-0"
        >
          <template v-slot:after-heading>
            <div class="display-2 font-weight-light">Ajustes de BDGD</div>
          </template>
          <v-row>
            <v-col cols="12">
              <v-btn
                v-if="accessReleased('ATUALIZACOES_DE_BDGD_ESCOLHER_TIPO')"
                id="btn-nova-atualizacao-bdgd"
                color="success"
                dark
                elevation="1"
                class="mt-5"
                style="float: left"
                relative
                text
                medium
                @click="$router.push('escolher-tipo')"
              >
                <v-icon
                  left
                  size="30px"
                >
                  mdi-plus-circle
                </v-icon>
                Novo Ajuste
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-tabs
                v-model="tabs"
                fixed-tabs
              >
                <v-tabs-slider></v-tabs-slider>
                <v-tab
                  href="#tab-manual"
                  class="primary--text"
                >
                  <v-icon left> mdi-table-edit </v-icon>
                  Em Tela
                </v-tab>
                <v-tab
                  v-if="accessReleased('ATUALIZACOES_DE_BDGD_ADICIONAR')"
                  href="#tab-excel"
                  class="primary--text"
                >
                  <v-icon left> mdi-microsoft-excel </v-icon>
                  Via Excel
                </v-tab>
                <v-tab
                  v-if="accessReleased('ATUALIZACOES_DE_BDGD_SQL_ADICIONAR')"
                  href="#tab-massivos"
                  class="primary--text"
                >
                  <v-icon left> mdi-database-sync </v-icon>
                  Massivos
                </v-tab>
                <v-tab
                  v-if="
                    accessReleased(
                      'ATUALIZACOES_DE_BDGD_SCRIPT_MODELO_ADICIONAR'
                    )
                  "
                  href="#tab-scripts-modelos"
                  class="primary--text"
                >
                  <v-icon left> mdi-file-code-outline </v-icon>
                  Via Scripts Modelos
                </v-tab>
              </v-tabs>
              <v-tabs-items v-model="tabs">
                <v-tab-item
                  value="tab-manual"
                  class="mt-10 px-5"
                >
                  <select-all-bdgd-versions
                    label="Versão da BDGD"
                    :companyId="companyId"
                    @bdgdVersionSelected="bdgdVersionSelected"
                  />
                  <registros-bdgd-por-entidade-data-table
                    :bdgdVersao="bdgdVersion"
                    :itemsPerPage="5"
                  />
                  <logs-ajustes-manuais-entidades-bdgd-data-table
                    :bdgdVersao="bdgdVersion"
                    :itemsPerPage="10"
                    :limit="1000"
                  />
                </v-tab-item>
                <v-tab-item value="tab-excel">
                  <atualizacoes-bdgd-excel-data-table
                    :items="itemsExcel"
                    :loading="loadingExcel"
                    @rechargeTable="getAtualizacoesExcel"
                  />
                </v-tab-item>
                <v-tab-item value="tab-massivos">
                  <atualizacoes-bdgd-sql-data-table
                    :items="itemsSql"
                    :loading="loadingSql"
                    @rechargeTable="getAtualizacoesSql"
                  />
                </v-tab-item>
                <v-tab-item value="tab-scripts-modelos">
                  <atualizacoes-bdgd-scripts-modelos-data-table
                    :items="itemsScriptsModelos"
                    :loading="loadingScriptsModelos"
                    @rechargeTable="getAtualizacoesScriptsModelos"
                  />
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import refreshDataMixins from '@/mixins/refreshDataMixins';
import AtualizacoesBdgdService from '@/services/AtualizacoesBdgdService';
import AtualizacoesBdgdViaSqlService from '@/services/AtualizacoesBdgdViaSqlService';
import AtualizacoesBdgdViaScriptModeloService from '@/services/AtualizacoesBdgdViaScriptModeloService';
import AtualizacoesBdgdExcelDataTable from '@/components/geracao-bdgd/atualizacoes-bdgd/AtualizacoesBdgdDataTable';
import AtualizacoesBdgdSqlDataTable from '@/components/geracao-bdgd/atualizacoes-bdgd/sql/AtualizacoesBdgdDataTable';
import AtualizacoesBdgdScriptsModelosDataTable from '@/components/geracao-bdgd/atualizacoes-bdgd/scripts-modelos/AtualizacoesBdgdDataTable';
import RegistrosBdgdPorEntidadeDataTable from '@/components/general/data-tables/RegistrosBdgdPorEntidadeDataTable';
import SelectAllBdgdVersions from '@/components/general/SelectAllBdgdVersions.vue';
import LogsAjustesManuaisEntidadesBdgdDataTable from '@/views/geracao-bdgd/entidade-bdgd/LogsAjustesManuaisEntidadesBdgdDataTable';
import profilePermissionsMixin from '@/mixins/profilePermissionsMixin';

export default {
  name: 'AtualizacoesBdgdHistorico',
  mixins: [refreshDataMixins, profilePermissionsMixin],
  components: {
    AtualizacoesBdgdExcelDataTable,
    AtualizacoesBdgdSqlDataTable,
    AtualizacoesBdgdScriptsModelosDataTable,
    RegistrosBdgdPorEntidadeDataTable,
    SelectAllBdgdVersions,
    LogsAjustesManuaisEntidadesBdgdDataTable
  },
  data: () => ({
    itemsExcel: [],
    itemsSql: [],
    itemsScriptsModelos: [],
    loadingExcel: false,
    loadingSql: false,
    loadingScriptsModelos: false,
    tabs: null,
    tabsKeySessionStorage: 'tab-atualizacoes-bdgd',
    bdgdVersion: { id: 0 }
  }),
  computed: {
    companyId() {
      return this.$store.getters.getCompanyId;
    }
  },
  mounted() {
    this.refreshData(() => {
      this.getAtualizacoesExcel();
      this.getAtualizacoesSql();
      this.getAtualizacoesScriptsModelos();
    });
    let tabSelecionada = sessionStorage.getItem(this.tabsKeySessionStorage);
    if (!tabSelecionada) tabSelecionada = 'tab-excel';
    this.tabs = tabSelecionada;
  },
  methods: {
    getAtualizacoesExcel() {
      this.loadingExcel = true;
      AtualizacoesBdgdService.getAtualizacoes()
        .then((response) => (this.itemsExcel = response))
        .finally(() => (this.loadingExcel = false));
    },
    getAtualizacoesSql() {
      this.loadingSql = true;
      AtualizacoesBdgdViaSqlService.getAtualizacoes()
        .then((response) => (this.itemsSql = response))
        .finally(() => (this.loadingSql = false));
    },
    getAtualizacoesScriptsModelos() {
      this.loadingScriptsModelos = true;
      AtualizacoesBdgdViaScriptModeloService.getAtualizacoes()
        .then((response) => (this.itemsScriptsModelos = response))
        .finally(() => (this.loadingScriptsModelos = false));
    },
    bdgdVersionSelected(bdgdVersion) {
      this.bdgdVersion = bdgdVersion;
    }
  },
  watch: {
    tabs() {
      sessionStorage.setItem(this.tabsKeySessionStorage, this.tabs);
    }
  }
};
</script>
